export const conferenceSpeakers = {
  title: "World Dental Conference",
  theme: "Embracing Innovations in Dentistry",
  location: "Bali, Indonesia",
  organizedBy: "World Dentist Association (WDA)",
  categories: [
    {
      category: "Keynote Speakers",
      speakers: [
        {
          name: "Dr. Pramod John",
          country: "India",
          topic: "Radiographic Assessment for Implant Placement",
          images: "1.webp",
        },
        {
          name: "Dr. Rudi Wigjanto",
          country: "Indonesia",
          topic: "New Generation of Magnetic Attachment: Strong and Slim",
          images: "2.webp",
        },
        {
          name: "Dr. Shenuka Singh",
          country: "South Africa",
          topic: "The Ethics of Generative Artificial Intelligence in Oral and Dental Research: A Time for Reflection",
          images: "3.webp",
        },
        {
          name: "Dr. Girish PV",
          country: "India",
          topic: "Biomechanical Concepts and its Application in TMD and Sleep Apnoea Patients",
          images: "4.webp",
        },
        {
          name: "Dr. Sundarraj Lakshmiah",
          country: "UK",
          topic: "Demystifying White and Red Oral Lesions: A Practical Approach for Dentists",
          images: "5.webp",
        },
        {
          name: "Dr. A.V. Arun",
          country: "India",
          topic: "Impacted Teeth: Bringing Them to Light",
          images: "6.webp",
        },
        {
          name: "Dr. Sharanya Sabrish",
          country: "India",
          topic: "Pathogenic Lesions in the Era of Aligners and Self-Ligating Appliances",
          images: "7.webp",
        },
        {
          name: "Dr. Ashish Singh",
          country: "India",
          topic: "Scope of Facial Aesthetics in Dentistry",
          images: "8.webp",
        },
        {
          name: "Dr. Ratna Parameshwaran",
          country: "India",
          topic: "Borderline Cases - The Roshamon Phenomenon",
          images: "9.webp",
        },
        {
          name: "Dr. Komang Teguh",
          country: "Indonesia",
          topic: "Oral Surgeon, Indonesia",
          images: "10.webp",
        },
        {
          name: "Dr. Shiv Kumar",
          country: "India",
          topic: "Pterygoid Implant Anatomical Considerations and Easy Approach Using Cortical Implants",
          images: "11.webp",
        },
        {
          name: "Dr. Felix Thungadi",
          country: "Indonesia",
          topic: "Orthodontics, Indonesia",
          images: "12.webp",
        },
        {
          name: "Dr. Ashish Sethi",
          country: "India",
          topic: "Transforming Atrophic Maxilla with Same Day Implant and Tooth",
          images: "13.webp",
        },
        {
          name: "Dr. Bharath Kumar Jayam",
          country: "India",
          topic: "Management of Severely Atrophic Ridges with Cortico Basal Implants",
          images: "14.webp",
        },
        {
          name: "Dr. George Skariah P",
          country: "India",
          topic: "What Constitutes Negligence in Practice of Dentistry",
          images: "15.webp",
        },
        {
          name: "Dr. Monisha Ravishankar",
          country: "Canada",
          topic: "Wellbeing and Mental Health in Dentistry - The Dentist Mindset",
          images: "16.webp",
        },
        {
          name: "Dr. Sumit Gupta",
          country: "UAE",
          topic: "Dental Sleep Medicine - 21st Century Dentistry",
          images: "17.webp",
        },
        {
          name: "Dr. Shahul Hameed",
          country: "India",
          topic: "Conventional to Corticobasal Implantology: A Paradigm Shift in the Rehabilitation of Compromised Maxillary and Mandibular Edentulism",
          images: "18.webp",
        },
        {
          name: "Dr. Priyank Mittal",
          country: "India",
          topic: "Rigid Fixation in Implantology",
          images: "19.webp",
        },
      ],
    },
    {
      category: "Guest Speakers",
      speakers: [
        {
          name: "Dr. SS Narayana",
          country: "India",
          topic: "Comprehensive Approach for Occlusal Stability: An Aid in Promoting Prevention and Supporting Restorations for Anterior and Posterior Teeth",
          images: "20.webp",
        },
        {
          name: "Dr. Biju K.A.",
          country: "India",
          topic: "Leveraging AI Step by Step Towards Traditional Orthodontics",
          images: "21.webp",
        },
        {
          name: "Dr. Sudhakar Marimeni",
          country: "UK",
          topic: "Radiotherapy and Implant-Related Complications: A Minimally Invasive Approach",
          images: "22.webp",
        },
        {
          name: "Dr. Gopinath Vivekanandan",
          country: "India",
          topic: "Systemic Medicine in Periodontology",
          images: "23.webp",
        },
        {
          name: "Dr. Deepavalli Arumuganan",
          country: "India",
          topic: "Innovative Drug for Managing Chronic Periodontitis",
          images: "24.webp",
        },
        {
          name: "Dr. Uwalia Bhankar",
          country: "India",
          topic: "Comparing Advanced Technologies and Innovations in Teaching Methods for Dental Sciences",
          images: "25.webp",
        },
        {
          name: "Dr. Chanchal Gupta",
          country: "India",
          topic: "The Pesky Scalpel - The New Evolution of Light",
          images: "26.webp",
        },
          {
          name: "Dr. Asha Queen",
          country: "India",
          topic: "Soft Tissue Surgery, Face Aesthetics and Permanent Makeup",
          images: "27.webp",
        },
        {
          name: "Dr. Rakshagan",
          country: "India",
          topic: " Advancements in Periodontal Esthetics: A New Era",
          images: "28.webp",
        },
        {
          name: "Dr. Amolack Singh",
          country: "India",
          topic: " Revolutionizing Dentistry: The Dawn of a New Era",
          images: "29.webp",
        },
        {
          name: "Dr. Dhaval V. Shah",
          country: "India",
          topic: " Conventional to Corticobasal Implantology",
          images: "30.webp",
        },
        {
          name: "Dr. Nayeemullah Khan",
          country: "India",
          topic: " Management of Class III",
          images: "31.webp",
        },
      
      ],
    },
    {
      category: "Rising Star Guest Speakers",
      speakers: [
        {
          name: "Dr. S. Akshay",
          country: "India",
          topic: "Small Supporting Bone for Minimal Access Levels of Periimplant",
          images: "32.webp",
        },
        {
          name: "Dr. Sneha Susan",
          country: "India",
          topic: "Early Childhood Caries and Lost Lesions",
          images: "33.webp",
        },
        {
          name: "Dr. Simi A Karim",
          country: "India",
          topic: "Pediatric Mandibular Fractures",
          images: "34.webp",
        },
        {
          name: "Dr. Jubna T",
          country: "India",
          topic: "Reconstruction of Avulsed Teeth",
          images: "35.webp",
        },
      ],
    },
  ],
};
