import React from "react";
import Footer from "../component/Footer/Footer";
import "./Speaker.css";
import BasicExample from "../component/Navbar/BasicExample";
import seven from "../assests/commitee/seven.png";
import eight from "../assests/commitee/eight.png";
import nine from "../assests/commitee/nine.png";
import { conferenceSpeakers } from "./Conference";


const Speakers = () => {

  console.log(conferenceSpeakers.categories[0].speakers)
  return (
    <div>
      <BasicExample />

      <div className="container-fluid col-lg-10 col-12">
      
  <div className="row mt-lg-5 mt-5 pt-5"> 
  <h2 className="text_fields text-center">Keynote Speakers </h2>
        {conferenceSpeakers.categories[0].speakers.map((item,i)=>{
          return(

            
    <div className="col-lg-3 col-md-6 col-12 mt-lg-4 mt-4" key={i}>
            <img
              src={require(`../assests/speaker/${item.images}`)}
              className="img-fluid center-block d-block mx-auto  speaker_images"
            />
            <h2 className="text-center speaker_head">{item.name}</h2>
            <p className="text-center speaker_para"><b>Topic:-</b> {item.topic} </p>
          </div>

          )

        })}
          </div>
      
  <div className="row mt-lg-5 mt-5 pt-5"> 
  <h2 className="text_fields text-center">Guest Speakers </h2>
        {conferenceSpeakers.categories[1].speakers.map((item,i)=>{
          return(

            
    <div className="col-lg-3 col-md-6 col-12 mt-lg-4 mt-4" key={i}>
            <img
              src={require(`../assests/speaker/${item.images}`)}
              className="img-fluid center-block d-block mx-auto  speaker_images"
            />
            <h2 className="text-center speaker_head">{item.name}</h2>
            <p className="text-center speaker_para"><b>Topic:-</b> {item.topic} </p>
          </div>

          )

        })}
          </div>
      
  <div className="row mt-5 pt-5"> 
  <h2 className="text_fields text-center">Rising Star Guest Speakers </h2>
        {conferenceSpeakers.categories[2].speakers.map((item,i)=>{
          return(

            
    <div className="col-lg-3 col-md-6 col-12 mt-lg-4 mt-4" key={i}>
            <img
              src={require(`../assests/speaker/${item.images}`)}
              className="img-fluid center-block d-block mx-auto  speaker_images"
            />
            <h2 className="text-center speaker_head">{item.name}</h2>
            <p className="text-center speaker_para"><b>Topic:-</b> {item.topic} </p>
          </div>

          )

        })}
          </div>

        </div>
      <Footer />
    </div>
  );
};

export default Speakers;
